// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-bio-jsx": () => import("./../../../src/pages/bio.jsx" /* webpackChunkName: "component---src-pages-bio-jsx" */),
  "component---src-pages-calendar-jsx": () => import("./../../../src/pages/calendar.jsx" /* webpackChunkName: "component---src-pages-calendar-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-roles-jsx": () => import("./../../../src/pages/roles.jsx" /* webpackChunkName: "component---src-pages-roles-jsx" */),
  "component---src-templates-category-jsx": () => import("./../../../src/templates/category.jsx" /* webpackChunkName: "component---src-templates-category-jsx" */),
  "component---src-templates-image-jsx": () => import("./../../../src/templates/image.jsx" /* webpackChunkName: "component---src-templates-image-jsx" */)
}

